import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { LeadDataModel } from 'src/app-core/data-model/lead-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadService } from 'src/app-core/services/lead-service.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Component({
	selector: 'app-edit-lead-form',
	templateUrl: './edit-lead-form.component.html',
	styleUrls: ['./edit-lead-form.component.scss']
})
export class EditLeadFormComponent implements OnInit {

	@ViewChild('editleadForm', { static: false }) editleadForm: any;
	@ViewChild('statusDropdownlist', { static: false }) statusDropdownlist!: DropDownListComponent;
	@ViewChild('closedReasonDropdownlist', { static: false }) closedReasonDropdownlist!: DropDownListComponent;
	commonSettings = new CommonSettings();
	commonDataModel = new CommonDataModel();
	leadDataModel = new LeadDataModel();
	editLeadFormPayload = _.cloneDeep(this.commonDataModel.editLeadFormPayload);
	leadStageDataSource = _.cloneDeep(this.commonDataModel.leadStageDataSource);
	formType: string = '';
	cityState: string = '';
	activeTabIndex: number = 0;
	isFormLoading = false;
	isContactLoading = false;
	companyFieldEmpty = false;
	leadId: any;
	selectedContact: any;
	selectedCompany: any;
	dropDownSourceList: any;
	companiesDataList: any[] = [];
	contactIds: any[] = [];
	contactList: any[] = [];
	removedAssociations: any[] = [];
	shippingAddressDataList: any[] = [];
	leadOwnerDataList: any[] = [];
	newContact!: {
		contact_id: number;
		name: string;
		company_name: string;
		industry_type: string;
	};
	minDate: Date = new Date();
	dateFormat = this.commonSettings.dateFormat;
	commonConstants: typeof CommonConstants = CommonConstants;

	constructor(
		private dialogRef: MatDialogRef<EditLeadFormComponent>,
		private matDialog: MatDialog,
		private commonMethod: CommonMethod,
		private commonService: CommonService,
		private leadService: LeadService,
		private customToastrService: CustomToastrService,
		@Inject(MAT_DIALOG_DATA) data?: any) {
		if (data) {
			this.formType = data?.formType;
			this.leadId = data?.leadId;
		}
	}

	ngOnInit(): void {
		this.onLoadItems();
	}

	onLoadItems(): void {
		this.isFormLoading = true;
        this.isContactLoading = true;
		this.leadService.getAllLeadOwnerList()
		.pipe(finalize(() => this.isFormLoading = false))
		.subscribe(response => {
			this.leadOwnerDataList = response.data;
		});

		this.leadService.getLeadFields()
			.pipe(finalize(() => this.isFormLoading = false))
			.subscribe(response => {
				this.dropDownSourceList = response.data.dd_fields;
				this.companiesDataList = response.data.dd_fields.companies;
			});
		this.leadService.getAllContactList()
			.pipe(finalize(() => this.isContactLoading = false))
			.subscribe(response => {
				this.contactList = response.data.contacts.map((contact: any) => ({
					...contact,
					isDisabled: false
				}));
			});

		this.leadService.editlead(this.leadId)
			.pipe(finalize(() => this.isFormLoading = false))
			.subscribe(response => {
				this.commonMethod.objectMapping(this.editLeadFormPayload, response.data);
				this.cityState = (this.editLeadFormPayload.city && this.editLeadFormPayload.state)
					? `${this.editLeadFormPayload.city}, ${this.editLeadFormPayload.state}`
					: '';
				this.editLeadFormPayload.bid_date = this.commonMethod.dateFieldMapping(response.data?.bid_date);
				this.editLeadFormPayload.deal_closed_date = this.commonMethod.dateFieldMapping(response.data?.deal_closed_date);
				this.editLeadFormPayload.expiration_date = this.commonMethod.dateFieldMapping(response.data?.expiration_date);
				this.editLeadFormPayload.deal_stage_date = this.commonMethod.dateFieldMapping(response.data?.deal_stage_date);
                this.editLeadFormPayload.startup_date = this.commonMethod.dateFieldMapping(response.data?.startup_date);
				this.editLeadFormPayload.deal_owner_id = Number(this.editLeadFormPayload.deal_owner_id);
				this.getShippingDetails();
			})
	}

	onCompanyChange(event: any) {
		const selectedCompany = this.companiesDataList.find(company => company.id === event);
		this.cityState = selectedCompany ? `${selectedCompany.city}, ${selectedCompany.state}` : '';
		this.editLeadFormPayload.country = selectedCompany ? selectedCompany.country : '';
		this.editLeadFormPayload.address = selectedCompany ? selectedCompany.address : '';
		this.editLeadFormPayload.zip = selectedCompany ? selectedCompany.zip : '';
		this.editLeadFormPayload.shipping_address_id = '';
		this.getShippingDetails();
	}

	getShippingDetails() {
		this.commonService.getShippingAddress(this.editLeadFormPayload.company_id)
			.pipe(finalize(() => this.isFormLoading = false))
			.subscribe(response => {
				this.shippingAddressDataList = response.data;
			});
	}

	addContacts() {
		if (this.selectedContact) {
			const contact = this.contactList.find(c => c.contact_id === this.selectedContact);

			if (contact) {
				this.newContact = {
					contact_id: contact.contact_id,
					name: contact.name,
					company_name: contact.company_name,
					industry_type: contact.industry_type,
				};

				this.editLeadFormPayload.stakeholder.push(this.newContact);
				this.editLeadFormPayload.association_changed = true;
				contact.isDisabled = true;
			}

			this.selectedContact = null;
		}
	}

	onDeleteAssociation(indexArray: number[]) {
		indexArray.sort((a, b) => b - a);
		indexArray.forEach(index => {
			const stakeholder = this.editLeadFormPayload.stakeholder[index];
			if (typeof stakeholder === 'object' && stakeholder !== null) {
				const contact = this.contactList.find(c => c.contact_id === stakeholder.contact_id);
				if (contact) {
					contact.isDisabled = false;
				}
				this.editLeadFormPayload.stakeholder.splice(index, 1);
			}
		});
		this.editLeadFormPayload.association_changed = true;
	}

	onFormSubmit(activeTabIndex: number) {
		if (activeTabIndex === 1) {
			this.onSubmit();
		}
	}

	onRedirectContactEditForm(dataItem: any, formType: string): void {
		this.matDialog.open(ContactFormComponent, {
			position: {
				right: '0',
			},
			panelClass: 'sm-dialog-wrapper',
			hasBackdrop: false,
			data: {
				id: dataItem.contact_id,
				formType: formType
			}
		}).afterClosed().subscribe((result: { confirmResult: boolean }) => {
			if (result?.confirmResult) {
				this.onLoadItems();
			}
		});
	}

	onNext() {
		this.isFormLoading = true;
		this.activeTabIndex = 1;
		const payloadToSend = { ...this.editLeadFormPayload };
		delete payloadToSend.stakeholder;
		delete payloadToSend.city;
		delete payloadToSend.state;
		delete payloadToSend.country;
		delete payloadToSend.zip;
		delete payloadToSend.address;
		delete payloadToSend.closedate;
		delete payloadToSend.closed_by;
		if (payloadToSend.status == 'Open' || payloadToSend.status == 'Pending') {
			payloadToSend.closed_reason = null;
		}
		payloadToSend.bid_date = this.commonMethod.convertDate(this.editLeadFormPayload.bid_date);
		payloadToSend.deal_closed_date = this.commonMethod.convertDate(this.editLeadFormPayload.deal_closed_date);
		payloadToSend.deal_stage_date = this.commonMethod.convertDate(this.editLeadFormPayload.deal_stage_date);
		payloadToSend.expiration_date = this.commonMethod.convertDate(this.editLeadFormPayload.expiration_date);
        payloadToSend.startup_date = this.commonMethod.convertDate(this.editLeadFormPayload.startup_date);

		this.leadService.updateLead(this.leadId, payloadToSend)
			.pipe(finalize(() => this.isFormLoading = false))
			.subscribe(response => {
				if (response?.isWarning) {
					this.customToastrService.warning(response?.message);
				} else {
					this.customToastrService.success(response?.message);
				}
			})
	}

	onTabChange(index: number): void {
		if (index === 1) {
			this.editLeadFormPayload.stakeholder.forEach((stakeholder: { contact_id: any; }) => {
				const contact = this.contactList.find(c => c.contact_id === stakeholder.contact_id);
				if (contact) {
					contact.isDisabled = true;
				}
			});
		}
	}

	onSubmit() {
		this.isFormLoading = true;
		if (this.newContact && this.removedAssociations.length > 0 && this.editLeadFormPayload.stakeholder.length === 0) {
			this.editLeadFormPayload.association_changed = false;
		}

		const payloadToSend = { ...this.editLeadFormPayload };
		payloadToSend.deal_id = this.leadId;
		delete payloadToSend.city;
		delete payloadToSend.state;
		delete payloadToSend.country;
		delete payloadToSend.zip;
		delete payloadToSend.address;
		delete payloadToSend.deal_owner_id;

		if (!payloadToSend.association_changed) {
			delete payloadToSend.stakeholder;
		} else {
			payloadToSend.stakeholder = payloadToSend.stakeholder.map((stakeholder: { contact_id: any; }) => stakeholder.contact_id);
		}
		this.leadService.updateStakeholder(payloadToSend)
			.pipe(finalize(() => this.isFormLoading = false))
			.subscribe(response => {
				this.customToastrService.success(response?.message);
				this.dialogRef.close({ confirmResult: true });
			});
	}

	itemDisabled(itemArgs: { dataItem: any }) {
		return itemArgs.dataItem?.isDisabled;
	}

	onBack() {
		this.activeTabIndex = 0;
		this.onLoadItems();
	}

	onClose() {
		this.dialogRef.close({ confirmResult: false });
	}

}
