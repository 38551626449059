<div class="details-box-wrapper">
    <div class="row mb-1 lead-edit-summary-status">
        <div class="col-8 ps-0">
            <div class="summary-title">Summary</div>
        </div>
        <div class="col-4 pe-0" *ngIf="isLeadDetailsRoute()">
            <span class="button-wrapper float-end">
                <span class="icon-button-wrapper" matTooltip="Edit" (click)="onShowEditSummary()">
                    <img src="assets/images/revenue-icon.svg" height="14" />
                </span>
            </span>
        </div>
    </div>
    <div class="text-wrapper">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.lead_id | valueCheck}}</div>
                    <div class="label">Lead ID</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.dealname | valueCheck}}</div>
                    <div class="label">Project Name</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">
                        {{leadDetailsModel?.legacy_id | valueCheck}}
                    </div>
                    <div class="label">Legacy M1 ID</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.business_developement | valueCheck}}</div>
                    <div class="label">Business Development</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.long_description | valueCheck}}</div>
                    <div class="label">
                       Description
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.project_shipping_address | valueCheck}}</div>
                    <div class="label">Project Shipping Address</div>
                </div>
            </div>
        </div>
    </div>
</div>